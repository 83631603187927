import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Form, Input, Button } from 'antd';

const { Title, Paragraph, Text } = Typography;

const Login = ({ loading, handleLogin, handleInputChange, error }) => {
  return (
    <div className="user-form-wrapper">
      <Title>Log in</Title>
      <Form name="form" labelCol={{ span: 8 }} onFinish={handleLogin}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input autoComplete="on" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password autoComplete="on" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Log in
          </Button>
        </Form.Item>
        {error && <Text className="user-form-wrapper__error">{error}</Text>}
        <Link to="/reset">Forgotten password?</Link>
      </Form>
      <Paragraph>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </Paragraph>
    </div>
  );
};

export default Login;
