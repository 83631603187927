import React, { useState, useCallback } from 'react';

import AccountSettings from './component';

const AccountSettingsContainer = () => {
  const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);

  const showDeleteAccountModal = () => {
    setDeleteAccountModalVisible(true);
  };

  const cancelDeleteAccountModal = useCallback(() => {
    setDeleteAccountModalVisible(false);
  }, []);

  return (
    <AccountSettings
      deleteAccountModalVisible={deleteAccountModalVisible}
      cancelDeleteAccountModal={cancelDeleteAccountModal}
      showDeleteAccountModal={showDeleteAccountModal}
    />
  );
};

export default AccountSettingsContainer;
