import { gql } from '@apollo/client';

export const CREATE_HOTEL = gql`
  mutation createHotel($input: CreateHotelInput!) {
    createHotel(input: $input) {
      hotel {
        id
        name
        deviceRegistrationCodes {
          code
          expiryDateTime
        }
        floors {
          id
          name
          displayOrder
          rooms {
            id
            name
            displayOrder
            floorId
            isDoNotDisturb
            isMakeUpRoom
            isOccupied
            gateway {
              lastConnectedDateTime
              lastDisconnectedDateTime
              isConnected
            }
          }
        }
      }
    }
  }
`;

export const DELETE_HOTEL = gql`
  mutation deleteHotel($input: DeleteHotelInput!) {
    deleteHotel(input: $input) {
      ok
    }
  }
`;

export const UPDATE_HOTEL = gql`
  mutation updateHotel($input: UpdateHotelInput!) {
    updateHotel(input: $input) {
      hotel {
        id
        name
        deviceRegistrationCodes {
          code
          expiryDateTime
        }
        floors {
          id
          name
          displayOrder
          rooms {
            id
            name
            displayOrder
            floorId
            isDoNotDisturb
            isMakeUpRoom
            isOccupied
            gateway {
              lastConnectedDateTime
              lastDisconnectedDateTime
              isConnected
            }
          }
        }
      }
    }
  }
`;

export const ENABLE_REG_CODE = gql`
  mutation createRegistrationCode($input: CreateRegistrationCodeInput!) {
    createRegistrationCode(input: $input) {
      code
    }
  }
`;

export const DISABLE_REG_CODE = gql`
  mutation deleteRegistrationCode($input: DeleteRegistrationCodeInput!) {
    deleteRegistrationCode(input: $input) {
      ok
    }
  }
`;
