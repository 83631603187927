import React from 'react';
import AppLayout from '../../components/AppLayout/component';
import moment from 'moment';
import { Typography, Table, Button } from 'antd';

import '../../../pages/Gateways/component.scss';

const { Title } = Typography;

const Gateways = ({ gateways }) => {
  const tableData = gateways?.map((gateway, index) => {
    return {
      key: index,
      mac: gateway.MAC,
      room: gateway.requestedRoomName,
      floor: gateway.requestedFloorName,
      linkedRoom: gateway.room?.name,
      linkedRoomOrder: gateway.room?.displayOrder,
      linkedFloor: gateway.room?.floor?.name,
      linkedFloorOrder: gateway.room?.floor?.displayOrder,
      date: moment(gateway.createdDateTime).format('LL'),
      id: gateway.id,
    };
  });

  const tableColumns = [
    {
      title: 'MAC',
      dataIndex: 'mac',
      key: 'mac',
      sorter: {
        compare: (a, b) => parseInt(a.mac, 16) - parseInt(b.mac, 16),
      },
    },
    {
      title: 'Room label',
      dataIndex: 'room',
      key: 'room',
    },
    {
      title: 'Floor label',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Linked room',
      dataIndex: 'linkedRoom',
      key: 'linkedRoom',
      sorter: {
        compare: (a, b) =>
          a.linkedRoomOrder +
          10000 * a.linkedFloorOrder -
          (b.linkedRoomOrder + 10000 * b.linkedFloorOrder),
      },
    },
    {
      title: 'Floor',
      dataIndex: 'linkedFloor',
      key: 'linkedFloor',
      sorter: {
        compare: (a, b) => a.linkedFloorOrder - b.linkedFloorOrder,
      },
    },
    {
      title: 'Added',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'action',
      render: () => {
        return (
          <div className="gateways-table-actions">
            <Button type="primary" disabled>
              Link to Room
            </Button>
            <Button type="primary" disabled>
              Unlink Room
            </Button>
            <Button type="primary" danger disabled>
              Unregister
            </Button>
          </div>
        );
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <AppLayout>
      <div className="gateways-page">
        <Title level={4}>Gateways</Title>
        <div className="gateways-actions">
          <Button disabled>Assign all Gateways to Rooms</Button>
        </div>
        <Table columns={tableColumns} dataSource={tableData} onChange={onChange} />
      </div>
    </AppLayout>
  );
};

export default Gateways;
