import { gql } from '@apollo/client';

export const CREATE_ROOM = gql`
  mutation createRoom($input: CreateRoomInput!) {
    createRoom(input: $input) {
      room {
        id
        name
        displayOrder
        floorId
        isDoNotDisturb
        isMakeUpRoom
        isOccupied
        gateway {
          lastConnectedDateTime
          lastDisconnectedDateTime
          isConnected
        }
      }
    }
  }
`;

export const EDIT_ROOM = gql`
  mutation updateRoom($input: UpdateRoomInput!) {
    updateRoom(input: $input) {
      room {
        id
        name
        displayOrder
        floorId
        isDoNotDisturb
        isMakeUpRoom
        isOccupied
        gateway {
          lastConnectedDateTime
          lastDisconnectedDateTime
          isConnected
        }
      }
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation deleteRoom($input: DeleteRoomInput!) {
    deleteRoom(input: $input) {
      ok
    }
  }
`;

export const SEND_GATEWAY_COMMAND = gql`
  mutation sendGatewayCommand($input: GatewayCommandInput!) {
    sendGatewayCommand(input: $input) {
      ok
    }
  }
`;
