import React from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const EditRoom = ({
  modalVisible,
  cancelModal,
  floors,
  onEditRoom,
  onDeleteRoom,
  selectedRoom,
  form,
}) => {
  return (
    <>
      <Modal
        forceRender
        title={`Edit Room: ${selectedRoom.name}`}
        visible={modalVisible}
        onCancel={() => {
          cancelModal();
          form.resetFields();
        }}
        okText="Save"
        onOk={() => {
          form.validateFields().then(() => {
            onEditRoom();
          });
        }}
      >
        <Form {...formLayout} form={form}>
          <Form.Item
            label="Room name"
            name="updatedRoom"
            initialValue={selectedRoom.name}
            rules={[{ required: true, message: 'Please input name of room' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="floor"
            label="Floor"
            initialValue={selectedRoom.floorId}
            rules={[{ required: true }]}
          >
            <Select>
              {floors?.map((floor) => {
                return (
                  <Option value={floor.id} key={floor.id}>
                    {floor.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" danger onClick={onDeleteRoom}>
              Delete room
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditRoom;
