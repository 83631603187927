import React from 'react';
import { Typography, Alert, Button, Row, Col } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import Floor from '../../components/Floor/component';
import AppLayout from '../../components/AppLayout/component';
import { AddFloor } from './modals/AddFloor';
import { EditFloor } from './modals/EditFloor';
import { AddRoom } from './modals/AddRoom';
import { EditRoom } from './modals/EditRoom';
import { RoomView } from './modals/RoomView';

import './component.scss';

const { Title, Text } = Typography;

const Dashboard = ({
  hotelName,
  floors,
  isEditDashboard,
  changeDashboardMode,
  addFloorModalVisible,
  editFloorModalVisible,
  addRoomModalVisible,
  editRoomModalVisible,
  roomViewModalVisible,
  showAddFloorModal,
  showEditFloorModal,
  showAddRoomModal,
  showEditorViewRoomModal,
  cancelAddFloorModal,
  cancelEditFloorModal,
  cancelAddRoomModal,
  cancelEditRoomModal,
  cancelRoomViewModal,
  selectedFloor,
  selectedRoom,
}) => {
  return (
    <AppLayout>
      <div className={classnames('dashboard', { editing: isEditDashboard })}>
        {isEditDashboard && (
          <Alert
            banner
            message={
              <div className="alert-box">
                <span>You are currently editing the Hotel layout. Click OK when done</span>
                <Button type="primary" onClick={changeDashboardMode}>
                  OK
                </Button>
              </div>
            }
          />
        )}
        <Title level={4}>{hotelName}</Title>
        <div className="floors">
          {floors.length !== 0 &&
            floors
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((floor) => {
                return (
                  <Floor
                    key={floor.id}
                    floor={floor}
                    showEditFloorModal={showEditFloorModal}
                    showAddRoomModal={showAddRoomModal}
                    showEditorViewRoomModal={showEditorViewRoomModal}
                  />
                );
              })}
          {isEditDashboard && (
            <Row className="add-floor">
              <Col span={1}>
                <PlusSquareOutlined onClick={showAddFloorModal} />
              </Col>
              <Col span={23} />
            </Row>
          )}
          {!floors.length && !isEditDashboard && (
            <Text>
              You don't have any floors. Please switch to edit mode on 'Hotel Settings' page and
              add them.
            </Text>
          )}
        </div>
      </div>
      <AddFloor modalVisible={addFloorModalVisible} cancelModal={cancelAddFloorModal} />
      <EditFloor
        modalVisible={editFloorModalVisible}
        cancelModal={cancelEditFloorModal}
        selectedFloor={selectedFloor}
      />
      <AddRoom
        modalVisible={addRoomModalVisible}
        cancelModal={cancelAddRoomModal}
        selectedFloor={selectedFloor}
      />
      <EditRoom
        modalVisible={editRoomModalVisible}
        cancelModal={cancelEditRoomModal}
        selectedRoom={selectedRoom}
      />
      <RoomView
        modalVisible={roomViewModalVisible}
        cancelModal={cancelRoomViewModal}
        selectedRoom={selectedRoom}
      />
    </AppLayout>
  );
};

export default Dashboard;
