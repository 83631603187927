import React from 'react';
import AppLayout from '../../components/AppLayout/component';
import moment from 'moment';
import { Typography, Table, Button } from 'antd';
import Loading from '../../components/Loading/component';
import UnlinkRoomModal from './modals/UnlinkRoom/component';
import LinkRoomModal from './modals/LinkRoom/component';
import DeleteGateway from './modals/DeleteGateway/component';

import './component.scss';

const { Title } = Typography;

const Gateways = ({
  gateways,
  gwLoading,
  mutationLoading,
  unlinkRoomModalVisible,
  showUnlinkRoomModal,
  closeUnlinkRoomModal,
  unlinkRoomHandler,
  linkRoomModalVisible,
  showLinkRoomModal,
  closeLinkRoomModal,
  linkRoomHandler,
  deleteGWModalVisible,
  showDeleteGWModal,
  closeDeleteGWModal,
  deleteGatewayHandler,
  handleLinkRoomSelect,
  selectedRoomIdtoLink,
}) => {
  const tableData = gateways?.map((gateway, index) => {
    return {
      key: index,
      mac: gateway.MAC,
      room: gateway.requestedRoomName,
      floor: gateway.requestedFloorName,
      linkedRoom: gateway.room?.name,
      linkedRoomOrder: gateway.room?.displayOrder,
      linkedFloor: gateway.room?.floor?.name,
      linkedFloorOrder: gateway.room?.floor?.displayOrder,
      date: moment(gateway.createdDateTime).format('LL'),
      id: gateway.id,
    };
  });

  const tableColumns = [
    {
      title: 'MAC',
      dataIndex: 'mac',
      key: 'mac',
      sorter: {
        compare: (a, b) => parseInt(a.mac,16) - parseInt(b.mac,16)
      },
    },
    {
      title: 'Room label',
      dataIndex: 'room',
      key: 'room'
    },
    {
      title: 'Floor label',
      dataIndex: 'floor',
      key: 'floor'
    },
    {
      title: 'Linked room',
      dataIndex: 'linkedRoom',
      key: 'linkedRoom',
      sorter: {
        compare: (a, b) => (a.linkedRoomOrder + 10000 * a.linkedFloorOrder) - (b.linkedRoomOrder + 10000 * b.linkedFloorOrder)
      },
    },
    {
      title: 'Floor',
      dataIndex: 'linkedFloor',
      key: 'linkedFloor',
      sorter: {
        compare: (a, b) => a.linkedFloorOrder - b.linkedFloorOrder
      },
    },
    {
      title: 'Added',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => moment(a.date).unix() - moment(b.date).unix()        
      },
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'action',
      render: (row) => {
        return (
          <div className="gateways-table-actions">
            <Button 
              type='primary'
              disabled={row.linkedRoom} 
              onClick={() => showLinkRoomModal(row)}              
            >
              Link to Room
            </Button>
            <Button
              type='primary'               
              disabled={!row.linkedRoom} 
              onClick={() => showUnlinkRoomModal(row)}
            >
              Unlink Room
            </Button>
            <Button 
              type='primary' 
              danger
              onClick={() => showDeleteGWModal(row)}               
            >
                Unregister
            </Button>
          </div>
        );
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <AppLayout>
      <div className="gateways-page">
        <Title level={4}>Gateways</Title>
        <div className="gateways-actions">
          <Button disabled>Assign all Gateways to Rooms</Button>
        </div>
        {gwLoading ? (
          <Loading />
        ) : (
          <Table columns={tableColumns} dataSource={tableData} onChange={onChange} />
        )}
      </div>
      <UnlinkRoomModal
        modalVisible={unlinkRoomModalVisible}
        cancelModal={closeUnlinkRoomModal}
        unlinkRoomHandler={unlinkRoomHandler}
        confirmLoading={mutationLoading}
      />
      <LinkRoomModal
        modalVisible={linkRoomModalVisible}
        cancelModal={closeLinkRoomModal}
        linkRoomHandler={linkRoomHandler}
        confirmLoading={mutationLoading}
        handleLinkRoomSelect={handleLinkRoomSelect}
        selectedRoomIdtoLink={selectedRoomIdtoLink}
      />
      <DeleteGateway
        modalVisible={deleteGWModalVisible}
        cancelModal={closeDeleteGWModal}
        deleteGatewayHandler={deleteGatewayHandler}
        confirmLoading={mutationLoading}
      />
    </AppLayout>
  );
};

export default Gateways;
