import React from 'react';

import { Layout } from 'antd';
import Header from '../Header/component';

import '../../../components/AppLayout/component.scss';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  return (
    <Layout className="site-layout">
      <Header />
      <Content>{children}</Content>
    </Layout>
  );
};

export default AppLayout;
