import { gql } from '@apollo/client';

export const CREATE_FLOOR = gql`
  mutation createFloor($input: CreateFloorInput!) {
    createFloor(input: $input) {
      floor {
        id
        name
        displayOrder
        rooms {
          id
          name
          displayOrder
          floorId
          isDoNotDisturb
          isMakeUpRoom
          isOccupied
          gateway {
            lastConnectedDateTime
            lastDisconnectedDateTime
            isConnected
          }
        }
      }
    }
  }
`;

export const EDIT_FLOOR = gql`
  mutation updateFloor($input: UpdateFloorInput!) {
    updateFloor(input: $input) {
      floor {
        id
        name
        displayOrder
        rooms {
          id
          name
          displayOrder
          floorId
          isDoNotDisturb
          isMakeUpRoom
          isOccupied
          gateway {
            lastConnectedDateTime
            lastDisconnectedDateTime
            isConnected
          }
        }
      }
    }
  }
`;

export const DELETE_FLOOR = gql`
  mutation deleteFloor($input: DeleteFloorInput!) {
    deleteFloor(input: $input) {
      ok
    }
  }
`;
