import React from 'react';

import RoomView from './component';

const RoomViewContainer = ({ modalVisible, cancelModal, selectedRoom }) => {

  const roomData = {
    floorId: '0c93770a-d637-4e6e-9e44-587cc162b407',
    id: '3e3242b9-a083-4c47-341c-ea84e6ee72cb',
    displayOrder: 3,
    isDoNotDisturb: false,
    isMakeUpRoom: false,
    isOccupied: false,
    name: '203',
    batteryLevel: 97,
    powerSaving: 37,
    gateway: {
      isConnected: false,
    },
  };

  return (
    <RoomView
      modalVisible={modalVisible}
      cancelModal={cancelModal}
      selectedRoom={selectedRoom}
      roomData={roomData}
    />
  );
};

export default RoomViewContainer;
