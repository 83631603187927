import React from 'react';
import classnames from 'classnames';
import { UserOutlined, DisconnectOutlined } from '@ant-design/icons';

import './component.scss';

const Room = ({ room, showEditorViewRoomModal }) => {
  const roomClassNames = classnames(
    'room-status',
    { 'dont-disturb': room.isDoNotDisturb },
    { occupied: room.isOccupied },
    { makeup: room.isMakeUpRoom },
    { disconected: !room.gateway?.isConnected }
  );

  return (
    <li className="room" onClick={() => showEditorViewRoomModal(room)}>
      <div className={roomClassNames}>
        {room.gateway?.isConnected && <UserOutlined />}
        {!room.gateway?.isConnected && <DisconnectOutlined />}
      </div>
      <div className="room-name">{room.name}</div>
    </li>
  );
};

export default Room;
