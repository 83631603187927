import React from 'react';
import { Typography, Card, Row, Col, Input, Button, Divider } from 'antd';

import DeleteAccount from './modals/DeleteAccount/container';
import AppLayout from '../../components/AppLayout/component';

import '../../../pages/AccountSettings/component.scss';

const { Title } = Typography;

const AccountSettings = ({
  deleteAccountModalVisible,
  cancelDeleteAccountModal,
  showDeleteAccountModal,
}) => {
  return (
    <AppLayout>
      <div className="account-settings">
        <Title level={4}>Account Settings</Title>
        <Card className="card-wrapper">
          <Row>
            <Col>Update email: </Col>
          </Row>
          <Row gutter={16} className="account-settings__row">
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <Input placeholder="Email" value="demo@demo.com" disabled />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 3 }}>
              <Button disabled>Save</Button>
            </Col>
          </Row>
          <Row>
            <Col>Update password: </Col>
          </Row>
          <Row gutter={16} className="account-settings__row">
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <Input.Password autoComplete="off" placeholder="Password" value="demo" disabled />
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 3 }}>
              <Button disabled>Save</Button>
            </Col>
          </Row>
          <Divider />
          <Button type="primary" danger onClick={showDeleteAccountModal}>
            Delete account
          </Button>
        </Card>
      </div>
      <DeleteAccount
        modalVisible={deleteAccountModalVisible}
        cancelModal={cancelDeleteAccountModal}
      />
    </AppLayout>
  );
};

export default AccountSettings;
