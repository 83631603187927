import React from 'react';
import gateways from './gateways';

import Gateways from './component';

const GatewaysContainer = () => {
  return <Gateways gateways={gateways} />;
};

export default GatewaysContainer;
