import React, { useEffect } from 'react';
import { message, Form } from 'antd';
import floors from '../../floors';

import EditRoom from './component';

const EditRoomContainer = ({ modalVisible, cancelModal, selectedRoom }) => {
  const [form] = Form.useForm();

  const updateRoomHandler = () => {
    message.info('Room is updated');
    cancelModal();
  };

  const deleteRoomHandler = () => {
    message.info('Room is deleted');
    cancelModal();
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedRoom, form]);

  return (
    <EditRoom
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      floors={floors}
      onEditRoom={updateRoomHandler}
      onDeleteRoom={deleteRoomHandler}
      selectedRoom={selectedRoom}
      form={form}
    />
  );
};

export default EditRoomContainer;
