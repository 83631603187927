import { gql } from '@apollo/client';

export const UNLINK_GATEWAY = gql`
  mutation unlinkGateway($input: UnlinkGatewayInput!) {
    unlinkGateway(input: $input) {
      gateway {
        id
        MAC
        requestedRoomName
        requestedFloorName
        createdDateTime
        room {
          id
          name
        }
      }
    }
  }
`;

export const LINK_GATEWAY = gql`
  mutation linkGateway($input: LinkGatewayInput!) {
    linkGateway(input: $input) {
      gateway {
        id
        MAC
        requestedRoomName
        requestedFloorName
        createdDateTime
        room {
          id
          name
        }
      }
    }
  }
`;

export const UNREGISTER_GATEWAY = gql`
  mutation deleteGateway($input: DeleteGatewayInput!) {
    deleteGateway(input: $input) {
      ok
    }
  }
`;
