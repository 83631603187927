import React from 'react';
import { Gauge } from '@ant-design/plots';

const max = 40;

const PowerGauge = ({ value }) => {
  const config = {
    percent: (value ?? 0) / max,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ['#F4664A', '#FAAD14', '#30BF78'],
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    statistic: {
      content: {
        content: `${value ?? 0}% Saving`,
        style: {
          fontSize: '30px',
          lineHeight: '36px',
        },
      },
    },
    axis: {
      label: {
        formatter: (text, item, index) => '',
      },
      tickInterval: 1,
    },
  };
  return <Gauge {...config} />;
};

export default PowerGauge;
