const gateways = [
  {
    id: '236a3fd1-4bc3-4fa9-a512-7d881bf9e9a7',
    MAC: '308398450A38',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T12:45:40.823Z',
    room: {
      id: '30d899de-b8e1-4543-9aaf-ce6ac72ad52f',
      name: '107',
      displayOrder: 7,
      floor: { name: 'First', displayOrder: 1, __typename: 'Floor' },
    },
  },
  {
    id: '9a5d4039-3a32-41e1-9cee-d9a1120683a9',
    MAC: '308398450A98',
    requestedRoomName: null,
    requestedFloorName: 'Teet',
    createdDateTime: '2022-03-23T11:19:31.861Z',
    room: {
      id: 'b1353eae-734b-4490-8f10-f7a4a8e89ea7',
      name: '118',
      displayOrder: 6,
      floor: { name: 'Second', displayOrder: 2, __typename: 'Floor' },
    },
  },
  {
    id: '60bab5cd-d11c-4876-9f88-09719e96fb76',
    MAC: 'E868E7085DA6',
    requestedRoomName: null,
    requestedFloorName: '3rd',
    createdDateTime: '2022-02-09T10:59:28.950Z',
    room: {
      id: '347e46ec-c16b-4e20-b0ac-6d05b69d2675',
      name: '1',
      displayOrder: 2,
      floor: { name: 'Fourth', displayOrder: 4, __typename: 'Floor' },
    },
  },
  {
    id: 'c72dc056-1657-4840-bc16-9f08eacf04a7',
    MAC: 'E868E7085DA9',
    requestedRoomName: null,
    requestedFloorName: '3rd',
    createdDateTime: '2022-02-07T14:12:34.407Z',
    room: {
      id: '596a3f61-b029-482f-a88f-bf6f956e1cca',
      name: '1',
      displayOrder: 3,
      floor: { name: 'Fourth', displayOrder: 4, __typename: 'Floor' },
    },
  },
  {
    id: '68722005-bb3d-43a4-ac3c-ce77dd47177b',
    MAC: 'E868E7085DA5',
    requestedRoomName: null,
    requestedFloorName: 'First',
    createdDateTime: '2022-03-22T17:50:06.676Z',
    room: {
      id: '3f7ce21a-c1e4-416c-83ca-f8e761df2159',
      name: '119',
      displayOrder: 7,
      floor: { name: 'Second', displayOrder: 2, __typename: 'Floor' },
    },
  },
  {
    id: '79baabf3-3327-4abe-b2e9-a24f6b5a87fd',
    MAC: '30839844FF3C',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T14:12:35.174Z',
    room: {
      id: 'b7089267-e33a-4cf6-acde-bc6d2f1377bf',
      name: '128',
      displayOrder: 4,
      floor: { name: 'Third', displayOrder: 3, __typename: 'Floor' },
    },
  },
  {
    id: '28e84c6a-7cb5-4baa-807a-c926787994bd',
    MAC: '308398450AB8',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T13:02:32.677Z',
    room: {
      id: '3a5c14d7-9d48-4268-ad90-c2dcee40b91a',
      name: '105',
      displayOrder: 5,
      floor: { name: 'First', displayOrder: 1, __typename: 'Floor' },
    },
  },
  {
    id: '95c6b96f-1b6b-4317-b330-4ce4a96411f1',
    MAC: '30839846BD70',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T13:04:04.043Z',
    room: {
      id: '343633dd-2d16-4366-a376-2694c747ca1a',
      name: '102',
      displayOrder: 2,
      floor: { name: 'First', displayOrder: 1, __typename: 'Floor' },
    },
  },
  {
    id: '8df383b4-0174-41d5-9d2d-b035731a99a1',
    MAC: 'E868E7086248',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T14:18:11.688Z',
    room: {
      id: '7ff8759c-1599-46dc-b836-34e6cf4f82ae',
      name: '103',
      displayOrder: 3,
      floor: { name: 'First', displayOrder: 1, __typename: 'Floor' },
    },
  },
  {
    id: '3e905835-95c1-4845-ad44-16cb57a99357',
    MAC: '240AC4D5FC4C',
    requestedRoomName: null,
    requestedFloorName: '999',
    createdDateTime: '2022-04-19T18:00:55.447Z',
    room: {
      id: '23607006-6117-49ee-a5f3-530af75c505b',
      name: 'Demo',
      displayOrder: 1,
      floor: { name: 'First', displayOrder: 1, __typename: 'Floor' },
    },
  },
  {
    id: 'ed883aac-5055-4465-b6e4-bd65ef0f6729',
    MAC: '30839846BD00',
    requestedRoomName: null,
    requestedFloorName: 'Test rig',
    createdDateTime: '2022-03-23T14:15:19.356Z',
    room: null,
  },
  {
    id: 'f1a6b38f-9652-4a5d-926d-5a5aa942d09f',
    MAC: 'BBCCDDEEFFAA',
    requestedRoomName: '301',
    requestedFloorName: 'Third',
    createdDateTime: '2022-04-19T11:42:46.845Z',
    room: null,
  },
];

export default gateways;
