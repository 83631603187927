import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Typography, Form, Input, Button, Result } from 'antd';

const { Title, Paragraph, Text } = Typography;

const ResetPassword = ({
  loading,
  handleResetPassword,
  successMessage,
  handleInputChange,
  error,
}) => {
  const history = useHistory();

  return !successMessage ? (
    <div className="user-form-wrapper">
      <Title>Forgotten Password?</Title>
      <Paragraph>Please enter your email address to reset password.</Paragraph>
      <Form name="form" labelCol={{ span: 6 }} autoComplete="off" onFinish={handleResetPassword}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input onChange={handleInputChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Reset password
          </Button>
        </Form.Item>
        {error && <Text className="user-form-wrapper__error">{error}</Text>}
        <Link to="/login">Back to Log in</Link>
      </Form>
    </div>
  ) : (
    <Result
      status="success"
      title="Password reset successfully!"
      subTitle="Please check your email to change your password."
      extra={[
        <Button type="primary" key="login" onClick={() => history.push('/')}>
          Go to Login
        </Button>,
      ]}
    />
  );
};

export default ResetPassword;
