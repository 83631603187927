import React, { useState, useCallback } from 'react';

import EditDashboradContext from '../../../contexts/EditDashboard';
import floors from './floors';

import Dashboard from './component';

const DashboardContainer = () => {
  const isEditHotelMode = JSON.parse(localStorage.getItem('isEditHotelMode'));
  const hotel = 'EcoHotel'
  const [isEditDashboard, setEditDashboard] = useState(isEditHotelMode.mode);

  const [addFloorModalVisible, setAddFloorModalVisible] = useState(false);
  const [editFloorModalVisible, setEditFloorModalVisible] = useState(false);
  const [addRoomModalVisible, setAddRoomModalVisible] = useState(false);
  const [editRoomModalVisible, setEditRoomModalVisible] = useState(false);
  const [roomViewModalVisible, setRoomViewModalVisible] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});

  const changeDashboardMode = () => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: false }));
    setEditDashboard(!isEditDashboard);
  };

  const showAddFloorModal = () => {
    setAddFloorModalVisible(true);
  };

  const showEditFloorModal = (floor) => {
    setSelectedFloor(floor);
    setEditFloorModalVisible(true);
  };

  const showAddRoomModal = (floor) => {
    setSelectedFloor(floor);
    setAddRoomModalVisible(true);
  };

  const showEditorViewRoomModal = (room) => {
    setSelectedRoom(room);
    if (isEditDashboard) {
      setEditRoomModalVisible(true);
    } else {
      setRoomViewModalVisible(true);
    }
  };

  const cancelAddFloorModal = useCallback(() => {
    setAddFloorModalVisible(false);
  }, []);

  const cancelEditFloorModal = useCallback(() => {
    setEditFloorModalVisible(false);
    setSelectedFloor({});
  }, []);

  const cancelAddRoomModal = useCallback(() => {
    setAddRoomModalVisible(false);
    setSelectedFloor({});
  }, []);

  const cancelEditRoomModal = useCallback(() => {
    setEditRoomModalVisible(false);
    setSelectedRoom({});
  }, []);

  const cancelRoomViewModal = useCallback(() => {
    setRoomViewModalVisible(false);
    setSelectedRoom({});
  }, []);

  return (
    <EditDashboradContext.Provider value={isEditDashboard}>
      <Dashboard
        hotelName={hotel}
        floors={floors}
        isEditDashboard={isEditDashboard}
        changeDashboardMode={changeDashboardMode}
        addFloorModalVisible={addFloorModalVisible}
        editFloorModalVisible={editFloorModalVisible}
        addRoomModalVisible={addRoomModalVisible}
        editRoomModalVisible={editRoomModalVisible}
        roomViewModalVisible={roomViewModalVisible}
        showAddFloorModal={showAddFloorModal}
        showEditFloorModal={showEditFloorModal}
        showAddRoomModal={showAddRoomModal}
        showEditorViewRoomModal={showEditorViewRoomModal}
        cancelAddFloorModal={cancelAddFloorModal}
        cancelEditFloorModal={cancelEditFloorModal}
        cancelAddRoomModal={cancelAddRoomModal}
        cancelEditRoomModal={cancelEditRoomModal}
        cancelRoomViewModal={cancelRoomViewModal}
        selectedFloor={selectedFloor}
        selectedRoom={selectedRoom}
      />
    </EditDashboradContext.Provider>
  );
};

export default DashboardContainer;
