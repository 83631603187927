import { gql } from '@apollo/client';

export const GET_GATEWAYS = gql`
  query {
    gateways {
      id
      MAC
      requestedRoomName
      requestedFloorName
      createdDateTime
      room {
        id
        name
        displayOrder
        floor {
          name
          displayOrder
        }
      }
    }
  }
`;
