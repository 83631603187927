import React from 'react';
import { message, Form } from 'antd';

import AddFloor from './component';

const AddFloorContainer = ({ modalVisible, cancelModal }) => {
  const [form] = Form.useForm();

  const createFloorHandler = () => {
    message.info('Floor is created');
    cancelModal();
  };

  return (
    <AddFloor
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onAddFloor={createFloorHandler}
      form={form}
    />
  );
};

export default AddFloorContainer;
