const floors = [
  {
      "id": "06ed261c-e332-43c4-b573-3d8695825b8a",
      "name": "First",
      "displayOrder": 1,
      "rooms": [
          {
              "id": "23607006-6117-49ee-a5f3-530af75c505b",
              "name": "Demo",
              "displayOrder": 1,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-05-01T07:29:26.442Z",
                  "lastDisconnectedDateTime": "2022-05-01T07:29:10.817Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "30d899de-b8e1-4543-9aaf-ce6ac72ad52f",
              "name": "107",
              "displayOrder": 7,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-05T04:09:46.892Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:24.041Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "343633dd-2d16-4366-a376-2694c747ca1a",
              "name": "102",
              "displayOrder": 2,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-05T04:09:46.870Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.120Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "3a5c14d7-9d48-4268-ad90-c2dcee40b91a",
              "name": "105",
              "displayOrder": 5,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T15:15:03.056Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.046Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "3c71de0b-7ebe-4676-ab34-bff1ebefad15",
              "name": "104",
              "displayOrder": 4,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "5c01a219-5524-4aaf-a18d-d86667a845c1",
              "name": "108",
              "displayOrder": 8,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "7ff8759c-1599-46dc-b836-34e6cf4f82ae",
              "name": "103",
              "displayOrder": 3,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "8e9a8a8d-3674-4c56-8109-355596fc04d8",
              "name": "110",
              "displayOrder": 10,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "d1073c42-d9fa-4385-9325-565f21b1744d",
              "name": "106",
              "displayOrder": 6,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "f69b9211-0f1b-462c-ac78-94ccf471bb0c",
              "name": "109",
              "displayOrder": 9,
              "floorId": "06ed261c-e332-43c4-b573-3d8695825b8a",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          }
      ],
      "__typename": "Floor"
  },
  {
      "id": "0c93770a-d637-4e6e-9e44-587cc162b407",
      "name": "Second",
      "displayOrder": 2,
      "rooms": [
          {
              "id": "3b31768f-9e7f-455f-a726-d4be4926909c",
              "name": "116",
              "displayOrder": 4,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "3f7ce21a-c1e4-416c-83ca-f8e761df2159",
              "name": "119",
              "displayOrder": 7,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": null,
                  "lastDisconnectedDateTime": null,
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "45f20c3e-d109-4657-9bc8-964e6459e135",
              "name": "121",
              "displayOrder": 9,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "4a666229-9e8e-422f-af7e-839cb33b236d",
              "name": "114",
              "displayOrder": 2,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "76987e49-5b18-4de8-ae37-777e3e34da92",
              "name": "113",
              "displayOrder": 1,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "994566d0-75a3-4365-8741-d3ad7bc65a34",
              "name": "115",
              "displayOrder": 3,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "ab3cb383-f9e3-47c8-a553-2dee43444862",
              "name": "122",
              "displayOrder": 10,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "ac27288c-57f9-482f-9260-dd774c1bcf27",
              "name": "120",
              "displayOrder": 8,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "b1353eae-734b-4490-8f10-f7a4a8e89ea7",
              "name": "118",
              "displayOrder": 6,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-05T08:43:18.384Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:46:18.763Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "fba7dffb-6f6d-43e3-b1a3-6f929bc0d0cc",
              "name": "117",
              "displayOrder": 5,
              "floorId": "0c93770a-d637-4e6e-9e44-587cc162b407",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          }
      ],
      "__typename": "Floor"
  },
  {
      "id": "cbe0cb3b-0738-4198-a024-6219897b9adc",
      "name": "Third",
      "displayOrder": 3,
      "rooms": [
          {
              "id": "03a3bc63-123e-48c7-9d32-2ac60173eca6",
              "name": "127",
              "displayOrder": 3,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "346c4422-0fdf-4326-be55-1a3b73e6fd21",
              "name": "132",
              "displayOrder": 8,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "45ac3562-4db4-47fe-a809-39993ca7d933",
              "name": "133",
              "displayOrder": 9,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "9e6867bb-69d5-44d6-8fbe-e8a8907c07aa",
              "name": "129",
              "displayOrder": 5,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "b7089267-e33a-4cf6-acde-bc6d2f1377bf",
              "name": "128",
              "displayOrder": 4,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": true,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-04T07:14:10.894Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.107Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "ea10132c-d31c-416b-af0a-86430f1a9ec5",
              "name": "134",
              "displayOrder": 10,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "ebb1e353-ef6d-4f98-bdb2-e7220b0e4579",
              "name": "130",
              "displayOrder": 6,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "f58e0973-2a06-47eb-baaf-69f3795d214b",
              "name": "126",
              "displayOrder": 2,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "f7f634de-918a-4ff2-82cb-38bfd459eac1",
              "name": "125",
              "displayOrder": 1,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          },
          {
              "id": "fb40f30f-d5d0-47b8-aa2f-a8098b3f97e8",
              "name": "131",
              "displayOrder": 7,
              "floorId": "cbe0cb3b-0738-4198-a024-6219897b9adc",
              "isDoNotDisturb": false,
              "isMakeUpRoom": false,
              "isOccupied": false,
              "gateway": {
                  "lastConnectedDateTime": "2022-04-03T21:45:38.087Z",
                  "lastDisconnectedDateTime": "2022-04-05T08:35:28.099Z",
                  "isConnected": true,
                  "__typename": "Gateway"
              },
              "__typename": "Room"
          }
      ],
      "__typename": "Floor"
  }
]

export default floors;
