import { gql } from '@apollo/client';

export const GET_HOTELS_WITH_DATA = gql`
  query {
    hotels {
      id
      name
      deviceRegistrationCodes {
        code
        expiryDateTime
      }
      floors {
        id
        name
        displayOrder
        rooms {
          id
          name
          displayOrder
          floorId
          isDoNotDisturb
          isMakeUpRoom
          isOccupied
          gateway {
            lastConnectedDateTime
            lastDisconnectedDateTime
            isConnected
          }
        }
      }
    }
  }
`;
