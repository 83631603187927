import React from 'react';
import { Typography, Card, Row, Col, Input, Button, Form } from 'antd';

import AppLayout from '../../components/AppLayout/component';
import { DeleteHotel } from './modals/DeleteHotel';

import './component.scss';

const { Title } = Typography;

const HotelSettings = ({
  hotel,
  code,
  updateHotelData,
  handleEditHotelClick,
  handleEnableRegClick,
  handleDisableRegClick,
  deleteHotelModalVisible,
  cancelDeleteHotelModal,
  showDeleteHotelModal,
  loading,
}) => {
  const { name, id } = hotel;

  const onHotelDataFormFinish = (data) => {
    updateHotelData(data);
  };

  return (
    <AppLayout>
      <div className="settings-page">
        <Title level={4}>Hotel Settings</Title>
        <Card className="card-wrapper">
          <Form
            name="hotelData"
            key={id}
            layout="vertical"
            onFinish={onHotelDataFormFinish}
            initialValues={{
              hotelName: name,
            }}
          >
            <Form.Item
              name="hotelName"
              label="Hotel name"
              rules={[{ required: true, message: 'Please input Hotel name' }]}
            >
              <Input placeholder="Hotel name" />
            </Form.Item>
            <Row className="settings-reg-buttons">
              <Col>
                <Button disabled={loading || code} onClick={handleEnableRegClick}>
                  Enable Registration
                </Button>
                <Button disabled={loading || !code} onClick={handleDisableRegClick}>
                  Disable Registration
                </Button>
              </Col>
            </Row>
            <Row className="code-input">
              <Col>
                <Input disabled value={code} />
              </Col>
            </Row>
            <Row justify="end" className="settings-buttons">
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Button type="primary" onClick={handleEditHotelClick}>
                  Edit Hotel Layout
                </Button>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Button htmlType="submit" disabled={loading}>
                    Save
                  </Button>
                  <Button danger type="primary" disabled={loading} onClick={showDeleteHotelModal}>
                    Delete Hotel
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <DeleteHotel modalVisible={deleteHotelModalVisible} cancelModal={cancelDeleteHotelModal} />
    </AppLayout>
  );
};

export default HotelSettings;
