import React from 'react';
import { Modal, Tabs, Progress, Typography, Button } from 'antd';
import PowerGauge from './gauge';

import './component.scss';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const RoomView = ({
  modalVisible,
  cancelModal,
  selectedRoom,
  roomData,
  handleSendGatewayCommand,
  sendingCommand,
}) => {
  return (
    <>
      <Modal
        title={`Room: ${selectedRoom.name}`}
        visible={modalVisible}
        onCancel={cancelModal}
        onOk={cancelModal}
        className="room-view"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Status" key="1">
            <div className="room-view__chart">
              <PowerGauge value={roomData?.room.powerSaving.saving} />
            </div>
            <div className="room-view__control">
              <Paragraph>Room Control</Paragraph>
              <Button
                onClick={() => {
                  handleSendGatewayCommand('ROOM_POWER_ON');
                }}
                disabled={sendingCommand || !roomData?.room?.gateway}
              >
                Room On
              </Button>
              <Button
                onClick={() => {
                  handleSendGatewayCommand('ROOM_POWER_OFF');
                }}
                disabled={sendingCommand || !roomData?.room?.gateway}
              >
                Room Off
              </Button>
            </div>
            <div>
              <h3>Battery</h3>
              <Progress percent={roomData?.room.batteryLevel ?? -1} />
            </div>
          </TabPane>
          <TabPane tab="Devices" key="2">
            In progress
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default RoomView;
