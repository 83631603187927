import { gql } from '@apollo/client';

export const GET_ROOM_WITH_DATA = gql`
  query room($id: UUID!) {
    room(id: $id) {
      name
      isOccupied
      isMakeUpRoom
      isDoNotDisturb
      batteryLevel
      powerSaving {
        saving
      }
      floorId
      gateway {
        id
        MAC
        lastConnectedDateTime
        lastDisconnectedDateTime
        isConnected
        messages {
          id
        }
      }
    }
  }
`;

export const GET_ROOMS_WITH_FLOOR = gql`
  query rooms($hotelId: UUID!) {
    rooms(hotelId: $hotelId) {
      id
      name
      gateway {
        id
      }
      floor {
        id
        name
      }
    }
  }
`;
