import React from 'react';
import { useHistory } from 'react-router-dom';

import DeleteHotel from './component';

const DeleteHotelContainer = ({ modalVisible, cancelModal }) => {
  const history = useHistory();

  const deleteHotelHandler = () => {
    localStorage.removeItem('hotel');
    history.push('/create-hotel');
  };

  return (
    <DeleteHotel
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onDeleteHotel={deleteHotelHandler}
    />
  );
};

export default DeleteHotelContainer;
