import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Layout, Menu, Row, Col, Dropdown, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import '../../../components/Header/component.scss';

const { Header } = Layout;

const MainHeader = () => {
  const history = useHistory();

  const handleSignOut = () => {
    history.push('/');
  };

  const navMenu = (
    <Menu className="site-header-nav">
      <Menu.Item key="1">
        <Link to="/account-settings">Account Settings</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/settings">Hotel Settings</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/gateways">Gateways</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Button onClick={() => handleSignOut()}>Log out</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="site-header">
      <Row align="middle" className="site-header-row">
        <Col span={12}>
          <Link to="/dashboard" className="logo">
            <div className="logo-img"></div>
          </Link>
        </Col>
        <Col span={12}>
          <Dropdown overlay={navMenu} placement="bottomRight" trigger="click">
            <UserOutlined />
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default MainHeader;
