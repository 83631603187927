import React from 'react';
import { Typography, Form, Input, Button } from 'antd';

const { Title, Paragraph } = Typography;

const ChangePassword = ({ loading, handleChangePassword }) => {
  return (
    <div className="user-form-wrapper">
      <Title>Change Password</Title>
      <Paragraph>Please enter your new password.</Paragraph>
      <Form name="form" labelCol={{ span: 8 }} autoComplete="off" onFinish={handleChangePassword}>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Change password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
