import React, { useEffect } from 'react';
import { message, Form } from 'antd';

import EditFloor from './component';

const EditFloorContainer = ({ modalVisible, cancelModal, selectedFloor }) => {
  const [form] = Form.useForm();

  const updateFloorHandler = () => {
    message.info('Floor is updated');
    cancelModal();
  };

  const deleteFloorHandler = () => {
    message.info('Floor is deleted');
    cancelModal();
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedFloor, form]);

  return (
    <EditFloor
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onEditFloor={updateFloorHandler}
      onDeleteFloor={deleteFloorHandler}
      selectedFloor={selectedFloor}
      form={form}
    />
  );
};

export default EditFloorContainer;
