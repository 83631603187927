import React from 'react';
import { Modal, Tabs, Progress } from 'antd';
import PowerGauge from '../../../../../pages/Dashboard/modals/RoomView/gauge';

import '../../../../../pages/Dashboard/modals/RoomView/component.scss';

const { TabPane } = Tabs;

const RoomView = ({ modalVisible, cancelModal, selectedRoom, roomData }) => {
  return (
    <>
      <Modal
        title={`Room: ${selectedRoom.name}`}
        visible={modalVisible}
        onCancel={cancelModal}
        onOk={cancelModal}
        className="room-view"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Status" key="1">
            <div className="room-view__chart">
              {/* <div>
                <Paragraph>
                  Room Status: {roomData?.isOccupied ? 'Occupied' : 'Unoccupied'}
                </Paragraph>
              </div> */}
              {/* <br/> */}
              {/* <h3>Power savings</h3> */}
              <PowerGauge value={roomData?.powerSaving} />
            </div>
            <div>
              <h3>Battery</h3>
              <Progress percent={roomData?.batteryLevel ?? -1} />
            </div>
          </TabPane>
          <TabPane tab="Devices" key="2">
            In progress
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default RoomView;
