import React from 'react';

import { Layout } from 'antd';
import { Header } from '../Header';

import './component.scss';

const { Content } = Layout;

const AppLayout = ({ hotelName, children }) => {
  return (
    <Layout className="site-layout">
      <Header hotelName={hotelName} />
      <Content>{children}</Content>
    </Layout>
  );
};

export default AppLayout;
