import React from 'react';
import { Typography, Form, Row, Col, Input, Button } from 'antd';
import AppLayout from '../../components/AppLayout/component';

import '../../../pages/CreateHotel/component.scss';

const { Title, Text } = Typography;

const CreateHotel = ({ createHotel }) => {
  const onHotelDataFormFinish = (values) => {
    createHotel(values);
  };

  return (
    <AppLayout>
      <div className="create-hotel">
        <Title level={2}>Welcome</Title>
        <Text>Lets get some basic details about your Hotel to start</Text>
        <Form
          name="hotelData"
          layout="vertical"
          onFinish={onHotelDataFormFinish}
          className="create-hotel-form"
        >
          <Row align="center" gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="hotelName"
                label="Hotel Name"
                rules={[{ required: true, message: 'Please input Hotel name' }]}
              >
                <Input placeholder="Hotel name" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button htmlType="submit">Create Hotel</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </AppLayout>
  );
};

export default CreateHotel;
