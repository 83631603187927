import { gql } from '@apollo/client';

export const SUBSCRIBE_ROOM_UPDATE = gql`
  subscription roomUpdated($hotelId: UUID!) {
    roomUpdated(hotelId: $hotelId) {
      id
      isDoNotDisturb
      isMakeUpRoom
      isOccupied
      gateway {
        lastConnectedDateTime
        lastDisconnectedDateTime
        isConnected
      }
    }
  }
`;
