import React from 'react';
import { Modal } from 'antd';

const DeleteHotel = ({ modalVisible, cancelModal, onDeleteHotel }) => {
  return (
    <>
      <Modal
        title="Delete Hotel"
        visible={modalVisible}
        onOk={onDeleteHotel}
        onCancel={cancelModal}
      >
        <h2>Are you sure?</h2>
        <p>This operation is permanent and will remove all floors, rooms and historical data.</p>
      </Modal>
    </>
  );
};

export default DeleteHotel;
