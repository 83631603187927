import { gql } from '@apollo/client';

export const SIGN_UP = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      userId
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      ok
    }
  }
`;
