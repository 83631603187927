import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import HotelSettings from './component';

const HotelSettingsContainer = () => {
  const [hotel, setHotel] = useState(localStorage.getItem('hotel'));
  const history = useHistory();
  const [deleteHotelModalVisible, setDeleteHotelModalVisible] = useState(false);

  const handleEditHotelClick = () => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: true }));
    history.push('/dashboard');
  };

  const updateHotelData = (data) => {
    setHotel(data.hotelName);
    localStorage.setItem('hotel', data.hotelName);
  };

  const showDeleteHotelModal = () => {
    setDeleteHotelModalVisible(true);
  };

  const cancelDeleteHotelModal = useCallback(() => {
    setDeleteHotelModalVisible(false);
  }, []);

  return (
    <HotelSettings
      hotel={hotel}
      code="CODE"
      updateHotelData={updateHotelData}
      handleEditHotelClick={handleEditHotelClick}
      deleteHotelModalVisible={deleteHotelModalVisible}
      cancelDeleteHotelModal={cancelDeleteHotelModal}
      showDeleteHotelModal={showDeleteHotelModal}
    />
  );
};

export default HotelSettingsContainer;
