import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Typography, Form, Input, Button, Result } from 'antd';

const { Title, Paragraph, Text } = Typography;

const Registration = ({ loading, handleSignUp, handleInputChange, successMessage, error }) => {
  const history = useHistory();

  return !successMessage ? (
    <div className="user-form-wrapper">
      <Title>Registration</Title>
      <Form name="form" labelCol={{ span: 8 }} onFinish={handleSignUp}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input autoComplete="off" onChange={handleInputChange} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password autoComplete="off" onChange={handleInputChange} />
        </Form.Item>
        {error && <Text className="user-form-wrapper__error">{error}</Text>}
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Sign up
          </Button>
        </Form.Item>
      </Form>
      <Paragraph>
        Already have an account? <Link to="/login">Log in</Link>
      </Paragraph>
    </div>
  ) : (
    <Result
      status="success"
      title="Successfully registered account!"
      subTitle="Please check your email to confirm registration."
      extra={[
        <Button type="primary" key="login" onClick={() => history.push('/')}>
          Go to Login
        </Button>,
      ]}
    />
  );
};

export default Registration;
