import React from 'react';
import { useHistory } from 'react-router-dom';

import DeleteAccount from './component';

const DeleteAccountContainer = ({ modalVisible, cancelModal }) => {
  const history = useHistory();

  const deleteUserHandler = () => {
    history.push('/');
  };

  return (
    <DeleteAccount
      cancelModal={cancelModal}
      modalVisible={modalVisible}
      onDeleteUser={deleteUserHandler}
    />
  );
};

export default DeleteAccountContainer;
