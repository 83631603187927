import React from 'react';
import { useHistory } from 'react-router-dom';

import CreateHotel from './component';

const CreateHotelContainer = () => {
  const history = useHistory();

  const createHotelHandler = (data) => {
    const { hotelName } = data;

    localStorage.setItem('hotel', hotelName);
    history.push('/dashboard');
  };

  return <CreateHotel createHotel={createHotelHandler} />;
};

export default CreateHotelContainer;
