import React from 'react';
import { Modal, Form, Input } from 'antd';

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const AddRoom = ({ modalVisible, cancelModal, onAddRoom, form }) => {
  return (
    <>
      <Modal
        title="Add Room"
        visible={modalVisible}
        onCancel={() => {
          cancelModal();
          form.resetFields();
        }}
        okText="Save"
        onOk={() => {
          form.validateFields().then(() => {
            onAddRoom();
          });
        }}
      >
        <Form {...formLayout} form={form}>
          <Form.Item
            label="Room name"
            name="room"
            rules={[{ required: true, message: 'Please input name of room' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddRoom;
