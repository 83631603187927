import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// import Login from './pages/Login/container';
import Dashboard from './pages/Dashboard/container';
import CreateHotel from './pages/CreateHotel/container';
import HotelSettings from './pages/HotelSettings/container';
import AccountSettings from './pages/AccountSettings/container';
import Gateways from './pages/Gateways/container';

const DemoApp = () => {
  useEffect(() => {
    localStorage.setItem('isEditHotelMode', JSON.stringify({ mode: false }));
  });

  return (
    <Switch>
      <Route exact path="/"><Redirect to="/dashboard" /></Route>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/create-hotel" component={CreateHotel} />
      <Route path="/settings" component={HotelSettings} />
      <Route path="/account-settings" component={AccountSettings} />
      <Route path="/gateways" component={Gateways} />
    </Switch>
  );
};

export default DemoApp;
