import React from 'react';
import { Modal } from 'antd';

const UnlinkRoomModal = ({ modalVisible, cancelModal, unlinkRoomHandler, confirmLoading }) => {
  return (
    <>
      <Modal
        title="Remove Linked Room"
        visible={modalVisible}
        onCancel={() => {
          cancelModal();
        }}
        confirmLoading={confirmLoading}
        okText="Remove"
        onOk={() => {
          unlinkRoomHandler();
        }}
      >
        Are you sure you want to remove linked Room?
      </Modal>
    </>
  );
};

export default UnlinkRoomModal;
